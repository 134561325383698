/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi API
 * API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type ExternalATSRemoteUserCfyiRole = typeof ExternalATSRemoteUserCfyiRole[keyof typeof ExternalATSRemoteUserCfyiRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExternalATSRemoteUserCfyiRole = {
  MEMBER: 'MEMBER',
  ADMIN: 'ADMIN',
  EDITOR: 'EDITOR',
  NON_APPROVED_MEMBER: 'NON_APPROVED_MEMBER',
} as const;
