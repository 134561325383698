/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi API
 * API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type FollowupStatus = typeof FollowupStatus[keyof typeof FollowupStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FollowupStatus = {
  pending: 'pending',
  completed: 'completed',
} as const;
