import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { FallbackRender } from "@sentry/nextjs";
import { FC } from "react";
import { CandidateFYILogo } from "../../assets";

const ErrorFallback: FallbackRender = ({ resetError }) => {
  return (
    <Flex
      bg="green.50"
      justifyContent={"center"}
      direction={"column"}
      alignItems="center"
      h={"100vh"}
      p="24px"
    >
      <Box>
        <CandidateFYILogo height="200px" width="200px" />
      </Box>
      <Text
        color={"blue.900"}
        fontWeight={700}
        fontSize={{ base: "40px", md: "80px" }}
        mt={"40px"}
      >
        Looks like something went wrong
      </Text>
      <Text
        mt={"24px"}
        color={"gray.600"}
        fontWeight={500}
        fontSize={{ base: "24px", md: "40px" }}
      >
        The page you’re looking for can’t be found. Let’s try this again, shall
        we?
      </Text>
      <Button
        colorScheme="blue"
        bg={"blue.900"}
        mt={"40px"}
        borderRadius="full"
        fontWeight={500}
        fontSize="18px"
        onClick={resetError}
      >
        Try again
      </Button>
    </Flex>
  );
};

export default ErrorFallback;
