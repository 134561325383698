/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi API
 * API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type EmailSendFromType = typeof EmailSendFromType[keyof typeof EmailSendFromType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmailSendFromType = {
  system: 'system',
  personal: 'personal',
} as const;
