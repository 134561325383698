/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi API
 * API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type InterviewerTemplateSlotCreateUpdateSlotType = typeof InterviewerTemplateSlotCreateUpdateSlotType[keyof typeof InterviewerTemplateSlotCreateUpdateSlotType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterviewerTemplateSlotCreateUpdateSlotType = {
  tag: 'tag',
  individual: 'individual',
} as const;
