import { FormControl, FormHelperText } from "@chakra-ui/react";

export const candidiateTheme = {
  components: {
    Toast: {
      defaultProps: {
        position: "top-right",
      },
    },
    Form: {
      parts: ["container"],
      variants: {
        /// The default variant for FormControl
        inline: {
          container: {
            display: "grid",
            gridTemplateColumns: "min-content 1fr",
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: "14px",
        fontWeight: "400",
        color: "gray.700",
      },
    },
    Table: {
      baseStyle: {
        tr: {
          _last: {
            td: {
              borderBottom: "none",
            },
          },
        },
      },
      variants: {
        basic: {
          td: {
            background: "white",
            _first: {
              borderLeftRadius: "md",
            },
            _last: {
              borderRightRadius: "md",
            },
          },
        },
      },
    },
  },
  colors: {
    palegray: {
      "50": "#D9D9D9",
      "200": "#ECE8DF",
      "400": "#DBEAFE",
      "500": "#F7F5F1",
      "600": "#c4c2be",
      "800": "#94928E",
      "900": "#666461",
    },
    lightGreen: {
      "50": "#D6FFFF",
      "100": "#A3F4DF",
      "300": "#71C1AD",
      "400": "#727E80",
      "500": "#32C2AD",
      "50033": "#32C2AD33",
      "600": "#EAF9F7",
      "700": "#40907E",
      "800": "#006252",
      "900": "#00372A",
    },
    deepBlue: {
      "50": "#ffe1ff",
      "100": "#a67fdd",
      "200": "#7552ab",
      "300": "#46287b",
      "400": "#1E40AF",
      "500": "#15004E",
      "900": "#000027",
    },
    candidateYellow50: "#FDF2A3",
    candidateYellow: {
      "100": "#FFFFDF",
      "200": "#FCE647",
      "300": "#ffff7b",
      "500": "#FCE647",
      "700": "#c5b400",
      "800": "#c5b400",
      "900": "#c5b400",
    },
  },
};
