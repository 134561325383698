import { useEffect } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
// Set your APP_ID
export const APP_ID = process.env.INTERCOM_APP_ID;

// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom

// prettier-ignore
export const loadIntercom = () => {
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

// Initializes Intercom
export const bootIntercom = (options = {}) => {
  window &&
    APP_ID &&
    window.Intercom &&
    window.Intercom("boot", { app_id: APP_ID, ...options });
};

export const updateIntercom = () => {
  window && APP_ID && window.Intercom && window.Intercom("update");
};

export const IntercomProvider = ({ children }) => {
  const router = useRouter();
  const session = useSession();

  useEffect(() => {
    if (typeof window !== "undefined" && session?.data) {
      loadIntercom();
      bootIntercom({
        name: session?.data?.user?.name,
        email: session?.data?.user?.email,
        user_id: session?.data?.user?.id,
        company: {
          company_id: session?.data?.user?.org?.id,
          name: session?.data?.user?.org?.name,
        },
      });
    }
  }, [session]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (typeof window !== "undefined") {
        updateIntercom();
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  return children;
};
