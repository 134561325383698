/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi API
 * API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type FeatureFlagFeature = typeof FeatureFlagFeature[keyof typeof FeatureFlagFeature];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeatureFlagFeature = {
  unsubscribe_pulse_emails: 'unsubscribe_pulse_emails',
  resend_failed_magic_links: 'resend_failed_magic_links',
  pulse_completion_alert: 'pulse_completion_alert',
  ai_block_creation: 'ai_block_creation',
  slack_intergration: 'slack_intergration',
  rejected_email_pulse: 'rejected_email_pulse',
  job_activation_modal: 'job_activation_modal',
  insights: 'insights',
  portal_invitation: 'portal_invitation',
  recuiter_job_not_live_alert: 'recuiter_job_not_live_alert',
  interviewer_followups: 'interviewer_followups',
  candidate_followup_alert: 'candidate_followup_alert',
  interview_scheduling: 'interview_scheduling',
  zoom_integration: 'zoom_integration',
  add_cfyi_email_to_attendees: 'add_cfyi_email_to_attendees',
  group_scheduling: 'group_scheduling',
  google_integration_revoke: 'google_integration_revoke',
  microsoft_sso: 'microsoft_sso',
  zoom_org_integration: 'zoom_org_integration',
  scheduling_rule_slots: 'scheduling_rule_slots',
  bright_hire_integration: 'bright_hire_integration',
  bright_hire_booking_links: 'bright_hire_booking_links',
  metaview_integration: 'metaview_integration',
  scheduling_emailing: 'scheduling_emailing',
  scheduling_reporting: 'scheduling_reporting',
  slack_channel: 'slack_channel',
  is_beta: 'is_beta',
  calendar_room_integrations: 'calendar_room_integrations',
  zoom_host_pools: 'zoom_host_pools',
  coderpad_integration: 'coderpad_integration',
  auto_replace_declined_interviewers: 'auto_replace_declined_interviewers',
  okta_integration: 'okta_integration',
  training_path: 'training_path',
  training_progress_report: 'training_progress_report',
  scheduling_page: 'scheduling_page',
  prefilter_large_interviewer_set: 'prefilter_large_interviewer_set',
  auto_sync_misconfigured_scheduled_request: 'auto_sync_misconfigured_scheduled_request',
  coupon_block: 'coupon_block',
  confidential_rounds: 'confidential_rounds',
  freebusy_whitelisting: 'freebusy_whitelisting',
  same_day_calendar_sharing: 'same_day_calendar_sharing',
  recruiting_events: 'recruiting_events',
  posthog_tracking_disabled: 'posthog_tracking_disabled',
  hackerrank_integration: 'hackerrank_integration',
  offices_syncing: 'offices_syncing',
} as const;
