/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi API
 * API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type SchedulingRequestCandidateAvailabilitySelectionInterval = typeof SchedulingRequestCandidateAvailabilitySelectionInterval[keyof typeof SchedulingRequestCandidateAvailabilitySelectionInterval];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SchedulingRequestCandidateAvailabilitySelectionInterval = {
  NUMBER_15: 15,
  NUMBER_30: 30,
  NUMBER_60: 60,
  null: null,
} as const;
