/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi API
 * API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type UserPreferencePulseAlertPreference = typeof UserPreferencePulseAlertPreference[keyof typeof UserPreferencePulseAlertPreference];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserPreferencePulseAlertPreference = {
  none: 'none',
  all_jobs: 'all_jobs',
  my_jobs_only: 'my_jobs_only',
} as const;
