export enum JobsEnum {
  JOBS = "jobs",
  GET_JOB_DETAILS = "getJobDetails",
  GET_ALL_EXTERNAL_JOBS = "getAllExternalJobs",
  GET_CURRENT_JOB_DETAILS = "getCurrentJobDetails",
  GET_JOB_STAGES = "getJobStages",
  GET_JOB_STAGES_BLOCKS = "getJobStagesBlocks",
  GET_JOB_STATS = "getJobStats",
  GET_JOBS_STAGES_LIST = "getJobsStagesList",
}

export enum CandidatesEnum {
  GET_CANDIDATES_LIST_DATA = "getCandidatesListData",
  GET_CANDIDATES_BY_ID = "getCandidatesById",
  GET_CANDIDATE_APPLICATION_DATA = "getCandidateApplication",
  GET_CANDIDATE_SESSIONS_DATA = "getCandidateSessionsData",
  GET_CANDIDATE_STATS = "getCandidateStats",
}

export enum SettingsEnum {
  GET_SETTINGS = "getSettings",
}

export enum TeamEnum {
  ALL_TEAM_DATA_LIST = "getAllTeamData",
  GET_ORG_MEMBERS = "getOrgMembers",
}

export enum IntegrationsEnum {
  GET_ORG_INTEGRATIONS = "getOrgIntegration",
}

export enum PulsesEnum {
  GET_PULSES_DATA = "getPulsesData",
}

export enum AtsIntegrationStatus {
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
  RELINK_NEEDED = "RELINK_NEEDED",
}
export enum EmojisName {
  ANGRY = "angry",
  BAD = "bad",
  SAD = "sad",
  HAPPY = "happy",
  SHINE = "shine",
}

export enum StatsEnum {
  GET_ALL_STATS = "getAllStats",
  GET_ALL_SESSIONS_STATS = "getAllSessionsStats",
}

export enum LibraryEnum {
  GET_LIBRARY_BLOCKS = "getLibraryBlocks",
  GET_LIBRARY_BLOCK = "getLibraryBlock",
}

export enum ApplicationsEnum {
  GET_ALL_APPLICATIONS = "getAllApplications",
}
