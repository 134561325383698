import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { CandidateFYILogo } from "../../assets";

export function AuthGuard({
  children,
  authRoles,
  rerouteDisabled = false,
  UnAuthedComponent,
}: {
  children: JSX.Element;
  authRoles?: string[];
  rerouteDisabled: boolean;
  UnAuthedComponent?: any;
}) {
  const { status, data } = useSession();
  const router = useRouter();
  const user: any = data?.user;

  useEffect(() => {
    if (status === "unauthenticated" && !rerouteDisabled) {
      router.replace("/login", "/");
    }
  }, [status, router]);

  // if no authRoles are specified, then we assume anyone can view this page
  if (status === "authenticated" && !authRoles) {
    return <>{children}</>;
  }
  // if authRoles are specified, then we check if the user has one of those roles
  if (status === "authenticated" && authRoles) {
    const userRole = user?.cfyiRole;
    const hasRole = authRoles.includes(userRole);
    if (hasRole) {
      return <>{children}</>;
    } else {
      return (
        <Flex
          bg="green.50"
          justifyContent={"center"}
          direction={"column"}
          alignItems="center"
          h={"100vh"}
          p="24px"
        >
          <Box>
            <CandidateFYILogo height="200px" width="200px" />
          </Box>
          <Text
            color={"blue.900"}
            fontWeight={700}
            fontSize={{ base: "40px", md: "60px" }}
            mt={"40px"}
            textAlign="center"
          >
            Looks like you don&apos;t have access to this page!
          </Text>
          <Text
            mt={"24px"}
            color={"gray.600"}
            fontWeight={500}
            fontSize={{ base: "24px", md: "40px" }}
          >
            Please contact your administrator for access.
          </Text>
          <Link href="/">
            <Button
              colorScheme="blue"
              bg={"blue.900"}
              mt={"40px"}
              borderRadius="full"
              fontWeight={500}
              fontSize="18px"
            >
              Back to homepage
            </Button>
          </Link>
        </Flex>
      );
    }
  }

  if (status === "unauthenticated" && UnAuthedComponent) {
    return <UnAuthedComponent />;
  }

  return null;
}
