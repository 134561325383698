import {
  HiOutlineBriefcase,
  HiOutlineIdentification,
  HiOutlineSignal,
  HiOutlineBookOpen,
  HiOutlineCog6Tooth,
  HiOutlineUserGroup,
  HiOutlineBolt,
  HiOutlineCalendar,
  HiOutlineQuestionMarkCircle,
  HiOutlineXCircle,
  HiOutlineMinusCircle,
  HiOutlineCheckCircle,
  HiOutlinePhone,
  HiOutlineVideoCamera,
  HiOutlineVideoCameraSlash,
} from "react-icons/hi2";
import { TbCalendarTime } from "react-icons/tb";
import { FaUserCheck } from "react-icons/fa";
import { SiGlassdoor } from "react-icons/si";
import {
  ExternalATSRemoteUserCfyiRole,
  SchedulingRequestCandidateAvailabilitySelectionInterval,
} from "../../api-client/orval/types";
import { MdOutlineInsights } from "react-icons/md";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import dayjs from "../../utils/dayjs";

export const DOTS = "showDots";
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_SIBLINGS_SIZE = 1;
export const DEFAULT_SKELETON_FADE_DURATION = 1;
export const MIN_PAGE = 1;
export const DOTS_UI = "...";
export const DEFAULT_SEARCH_DEBOUNCE = 200;
export const pageLinks = [
  { href: "/jobs", displayName: "Jobs", icon: HiOutlineBriefcase },
  {
    href: "/candidates",
    displayName: "Candidates",
    icon: HiOutlineIdentification,
  },
  { href: "/pulses", displayName: "Pulses", icon: HiOutlineSignal },
  { href: "/scheduling", displayName: "Scheduling", icon: HiOutlineCalendar },
  { href: "/insights", displayName: "Insights", icon: MdOutlineInsights },
  { href: "/library", displayName: "Library", icon: HiOutlineBookOpen },
];

export const settingsLinks = [
  { href: "/settings", displayName: "Settings", icon: HiOutlineCog6Tooth },
  { href: "/team", displayName: "Team", icon: HiOutlineUserGroup },
  { href: "/integrations", displayName: "Integrations", icon: HiOutlineBolt },
];

export const RECENT_USER_DAYS = 1;

export const JOB_STATUSES = [
  { value: null, label: "All Statuses" },
  { value: "OPEN", label: "Open" },
  { value: "CLOSED", label: "Closed" },
  { value: "DRAFT", label: "Draft" },
  { value: "ARCHIVED", label: "Archived" },
  { value: "PENDING", label: "Pending" },
];

export const APPROVED_USER_ROLES = [
  ExternalATSRemoteUserCfyiRole.ADMIN,
  ExternalATSRemoteUserCfyiRole.EDITOR,
  ExternalATSRemoteUserCfyiRole.MEMBER,
];

export const EDITOR_AND_ABOVE_ROLES = [
  ExternalATSRemoteUserCfyiRole.ADMIN,
  ExternalATSRemoteUserCfyiRole.EDITOR,
];

export const REQUIRED_GOOGLE_CAL_SCOPES = [
  "https://www.googleapis.com/auth/calendar.events",
  "https://www.googleapis.com/auth/calendar.readonly",
];

export const REQUIRED_MICROSOFT_CAL_SCOPES = ["Calendars.ReadWrite.Shared"];
let timezoneIdentifiers: any = (Intl as any).supportedValuesOf("timeZone");

const timezoneOptions = timezoneIdentifiers.map((timezone: any) => {
  const now = dayjs.tz(dayjs(), timezone);
  const offset = now.format("Z");
  const fullName = now.format("zzz");
  const label = `${fullName} (GMT${offset} - ${timezone.replaceAll("_", " ")})`;
  return { label, value: timezone };
});

export const TIME_ZONES = timezoneOptions;

export const LocationTypeIconMap: any = {
  phone: HiOutlinePhone,
  google_meet: HiOutlineVideoCamera,
  shared_google_meet: HiOutlineVideoCamera,
  shared_zoom: HiOutlineVideoCamera,
  zoom: HiOutlineVideoCamera,
  teams: HiOutlineVideoCamera,
  shared_teams: HiOutlineVideoCamera,
  no_conference: HiOutlineVideoCameraSlash,
};

export const CONFERENCE_LOCATION_TYPES_MAP: any = {
  phone: "Phone call",
  shared_google_meet: "Shared Google meet",
  shared_zoom: "Shared Zoom",
  google_meet: "Google meet",
  zoom: "Zoom",
  teams: "Teams",
};

export const ALL_CONFERENCE_LOCATION_TYPES: any = [
  { value: "phone", label: "Phone call" },
  { value: "google_meet", label: "Google meet" },
  { value: "shared_google_meet", label: "Shared Google meet" },
  { value: "teams", label: "Teams" },
  { value: "shared_teams", label: "Shared Teams" },
  { value: "zoom", label: "Zoom" },
  { value: "shared_zoom", label: "Shared Zoom" },
  { value: "no_conference", label: "No Conference" },
];

export const GCAL_INTEGRATION_REQUIRED_CODE = "gcal_integration_required";

export const CALENDAR_INTERGRATION_REQUIRED_CODE =
  "calendar_integration_required";

export const socialLinks = [
  {
    icon: FaFacebookF,
    label: "Facebook",
    settingsKey: "socialFacebookUrl",
  },
  {
    icon: FaTwitter,
    label: "Twitter",
    settingsKey: "socialTwitterUrl",
  },
  // instagram
  {
    icon: FaInstagram,
    label: "Instagram",
    settingsKey: "socialInstagramUrl",
  },
  // linkedin
  {
    icon: FaLinkedin,
    label: "LinkedIn",
    settingsKey: "socialLinkedinUrl",
  },
  // youtube
  {
    icon: FaYoutube,
    label: "YouTube",
    settingsKey: "socialYoutubeUrl",
  },
  // tiktok
  {
    icon: FaTiktok,
    label: "TikTok",
    settingsKey: "socialTiktokUrl",
  },
  // glassdoor
  {
    icon: SiGlassdoor,
    label: "Glassdoor",
    settingsKey: "socialGlassdoorUrl",
  },
];

export const interviewDurationOptions = [
  { label: "15 mins", value: 15 },
  { label: "30 mins", value: 30 },
  { label: "40 mins", value: 40 },
  { label: "45 mins", value: 45 },
  { label: "50 mins", value: 50 },
  { label: "60 mins", value: 60 },
  { label: "90 mins", value: 90 },
];

export const DEFAULT_SCHEDULING_DAYS = 14;

export const SCHEDULING_STATUS_MAP: any = {
  canceled: { label: "Canceled", color: "gray", percentage: 0 },
  pending: { label: "Pending", color: "gray", percentage: 25 },
  scheduling: { label: "Scheduling", color: "orange", percentage: 50 },
  confirming: { label: "Confirming", color: "orange", percentage: 75 },
  booked: { label: "Booked", color: "green", percentage: 100 },
};

export const SCHEDULING_REQUEST_TYPE_ICON_MAP: any = {
  self_booking: FaUserCheck,
  availability: TbCalendarTime,
};

export const EVENT_ATTENDING_STATUS_MAP: any = {
  needsAction: {
    icon: HiOutlineQuestionMarkCircle,
    color: "orange.500",
    label: "Awaiting Confirmation",
  },
  needs_action: {
    icon: HiOutlineQuestionMarkCircle,
    color: "orange.500",
    label: "Awaiting Confirmation",
  },
  declined: { icon: HiOutlineXCircle, color: "red", label: "Declined" },
  tentative: {
    icon: HiOutlineMinusCircle,
    color: "yellow",
    label: "Tentative",
  },
  accepted: { icon: HiOutlineCheckCircle, color: "green", label: "Accepted" },
};

export const AZURE_CALENDAR_PROVIDER = "azure-ad-calendar";
export const GOOGLE_CALENDAR_PROVIDER = "google-calendar";

export const ATS_INTERGRATION_TO_IMG_URL_PREFIX_MAP: any = {
  Teamtailor: "Teamtailor_Square_Logo.jpg",
  Ashby: "Ashby_Square_Logo.png",
  Recruitee: "Recruitee_Square_Logo.jpg",
  Lever: "Lever_Square_Logo.jpg",
  "Oracle Fusion - Recruiting Cloud": "Oracle_Recruiting_square.png",
  Greenhouse: "Greenhouse_Square_Logo.jpg",
  iCIMS: "PlatformICIMS_square.png",
  Bullhorn:
    "Property_1Integration_Property_2Generic_Property_3Square_Property_4Bullhorn.png",
};

export const DAYS = [
  { name: "Monday", value: 1 },
  { name: "Tuesday", value: 2 },
  { name: "Wednesday", value: 3 },
  { name: "Thursday", value: 4 },
  { name: "Friday", value: 5 },
  { name: "Saturday", value: 6 },
  { name: "Sunday", value: 7 },
];

export const CANDIDATE_SELECTION_INTERVAL_OPTIONS = Object.values(
  SchedulingRequestCandidateAvailabilitySelectionInterval
)
  .filter((key) => !!key)
  .map((key) => {
    return { label: key, value: key };
  });
